import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseApp } from "angularfire2";
import * as firebase from "firebase";
import "firebase/storage";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  // private API_URL = "http://localhost:3200/lotes/";
  private API_URL = "https://megadan.megadesk.com.br/lotes/";

  constructor(
    private http: HttpClient,
    @Inject(FirebaseApp) public firebaseApp: any
  ) {}

  noticias() {
    return this.http.get(this.API_URL + "listarnoticiastopo");
  }

  enviarMensagem(dados) {
    return this.http.post(this.API_URL + "enviar-mensagem", dados);
  }

  enviarCurriculo(dados) {
    return this.http.post(this.API_URL + "enviar-curriculo", dados);
  }

  uploadPhotoLancamento(file: any): firebase.storage.UploadTask {
    return this.firebaseApp
      .storage()
      .ref()
      .child(`curriculo/${file.lastModified}` + Date.now())
      .put(file);
  }

  listarBlog() {
    return this.http.get(this.API_URL + "listarblog");
  }

  detalheNews(id) {
    return this.http.post(this.API_URL + "news", { id });
  }
}
