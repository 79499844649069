import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../api.service";

@Component({
  selector: "app-blogdetalhe",
  templateUrl: "./blogdetalhe.component.html",
  styleUrls: ["./blogdetalhe.component.css"]
})
export class BlogdetalheComponent implements OnInit {
  id: any;
  dados: any;

  constructor(private route: Router, private api: ApiService) {
    this.id = this.route.getCurrentNavigation().extras.state.id;
    this.news(this.id);
  }

  ngOnInit() {}

  news(id) {
    this.api.detalheNews(id).subscribe(dado => {
      this.dados = dado;
      console.log(this.dados);
    });
  }
}
