import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { CurriculoComponent } from "./curriculo/curriculo.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogdetalheComponent } from "./blogdetalhe/blogdetalhe.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "curriculo", component: CurriculoComponent },
  { path: "blog", component: BlogComponent },
  { path: "blogdetalhe", component: BlogdetalheComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
