import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FirebaseApp } from "angularfire2";
import * as firebase from "firebase";
import "firebase/storage";
import { ApiService } from "../api.service";

@Component({
  selector: "app-curriculo",
  templateUrl: "./curriculo.component.html",
  styleUrls: ["./curriculo.component.css"]
})
export class CurriculoComponent implements OnInit {
  curriculo: FormGroup;
  arquivo: any;
  uploadTask: firebase.storage.UploadTask;
  uploadProgress: number;

  constructor(private form: FormBuilder, private api: ApiService) {
    this.curriculo = this.form.group({
      nome: ["", Validators.required],
      email: ["", Validators.required],
      setor: ["", Validators.required],
      curriculo: [""]
    });
  }

  ngOnInit() {}

  upload(event) {
    event.preventDefault();
    this.arquivo = (<HTMLInputElement>event.target).files[0];
  }

  enviarCurriculo() {
    if (this.arquivo) {
      this.uploadTask = this.api.uploadPhotoLancamento(this.arquivo);

      this.uploadTask.on(
        "state_changed",
        snapshot => {
          this.uploadProgress = Math.round(
            (this.uploadTask.snapshot.bytesTransferred /
              this.uploadTask.snapshot.totalBytes) *
              100
          );
        },
        (error: Error) => {
          // catch error
        },
        async () => {
          await this.uploadTask.snapshot.ref.getDownloadURL().then(res => {
            this.curriculo.controls["curriculo"].setValue(
              <HTMLInputElement>res
            );

            console.log(this.curriculo.value);
            this.api
              .enviarCurriculo(this.curriculo.value)
              .subscribe(info => {});
            this.curriculo.reset();
          });
        }
      );
    }
  }
}
