import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api.service";
import { Router } from "@angular/router";
import * as firebase from "firebase";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  dados: any;
  tempo: boolean = false;
  msgContato: FormGroup;
  nome: boolean = false;
  email: boolean = false;
  mensagem: boolean = false;
  enviado: boolean = false;

  marcas = [
    {
      link: "https://www.nestle.com.br/marcas/snow-flakes",
      img: "assets/img/logo/icon/snow.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nestle-aveias",
      img: "assets/img/logo/icon/aveia.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nestle-creme-de-leite",
      img: "assets/img/logo/icon/creme.png"
    },
    {
      link: "https://www.nestle.com.br/marcas/zerolacto",
      img: "assets/img/logo/icon/zero.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/tostines",
      img: "assets/img/logo/icon/tostine.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/talento",
      img: "assets/img/logo/icon/talento.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/suflair",
      img: "assets/img/logo/icon/suflair.png"
    },
    {
      link: "https://www.nestle.com.br/marcas/snow-flakes",
      img: "assets/img/logo/icon/snow.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/prestigio",
      img: "assets/img/logo/icon/prestigio.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/passatempo",
      img: "assets/img/logo/icon/passatempo.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/papinhas-nestle",
      img: "assets/img/logo/icon/papinha.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nutren",
      img: "assets/img/logo/icon/nutrn.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/ninho",
      img: "assets/img/logo/icon/ninho.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/neston",
      img: "assets/img/logo/icon/neston.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nesquik",
      img: "assets/img/logo/icon/nesquik.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/neslac",
      img: "assets/img/logo/icon/neslac.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nesfit",
      img: "assets/img/logo/icon/nesfit.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/nescau",
      img: "assets/img/logo/icon/nescau.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/dolce-gusto",
      img: "assets/img/logo/icon/dolce.png"
    },
    {
      link: "https://www.nestle.com.br/marcas/nescafe",
      img: "assets/img/logo/icon/nescafe.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/negresco",
      img: "assets/img/logo/icon/negresco.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/mucilon",
      img: "assets/img/logo/icon/mucilon.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/moca",
      img: "assets/img/logo/icon/moca.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/maggi",
      img: "assets/img/logo/icon/maggi.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/lion",
      img: "assets/img/logo/icon/lion.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/kitkat",
      img: "assets/img/logo/icon/kitkat.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/garoto",
      img: "assets/img/logo/icon/garoto.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/galak",
      img: "assets/img/logo/icon/galak.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/fibermais",
      img: "assets/img/logo/icon/fiber.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/farinha-lactea-nestle",
      img: "assets/img/logo/icon/farinha.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/dois-frades",
      img: "assets/img/logo/icon/doisfrade.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/crunch",
      img: "assets/img/logo/icon/crunch.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/corn-flakes",
      img: "assets/img/logo/icon/corn.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/classic",
      img: "assets/img/logo/icon/classic.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/chocolates-nestle",
      img: "assets/img/logo/icon/nestle.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/cheerios",
      img: "assets/img/logo/icon/cherrios.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/calipso",
      img: "assets/img/logo/icon/calipso.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/bono",
      img: "assets/img/logo/icon/bono.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/alpino",
      img: "assets/img/logo/icon/alpino.jpg"
    },
    {
      link: "https://www.nestle.com.br/marcas/baton",
      img: "assets/img/logo/icon/baton.jpg"
    }
  ];

  constructor(
    private form: FormBuilder,
    private api: ApiService,
    private route: Router
  ) {
    this.msgContato = this.form.group({
      nome: ["", Validators.required],
      email: ["", Validators.required],
      mensagem: ["", Validators.required]
    });
    firebase.analytics();
  }

  ngOnInit() {
    this.buscarNoticiasTopo();
    this.time();
  }

  buscarNoticiasTopo() {
    this.api.noticias().subscribe(data => {
      this.dados = data;
      console.log(this.dados);
    });
  }

  time() {
    setTimeout(() => {
      this.tempo = true;
    }, 4000);
  }

  enviarMSG() {
    if (this.msgContato.value["nome"]) {
      if (this.msgContato.value["email"]) {
        if (this.msgContato.value["mensagem"]) {
          this.api.enviarMensagem(this.msgContato.value).subscribe(() => {});
          this.msgContato.reset();
          this.enviado = true;
          this.mensagem = false;
          this.nome = false;
          this.email = false;
        } else {
          this.mensagem = true;
          this.enviado = false;
          this.email = false;
        }
      } else {
        this.email = true;
        this.enviado = false;
        this.nome = false;
      }
    } else {
      this.nome = true;
      this.enviado = false;
    }
  }

  newsDetalhe(id) {
    this.route.navigate(["blogdetalhe"], {
      state: { id }
    });
  }
}
