import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.css"]
})
export class BlogComponent implements OnInit {
  dados: any;

  constructor(private api: ApiService, private route: Router) {}

  ngOnInit() {
    this.listar();
  }

  listar() {
    this.api.listarBlog().subscribe(dado => {
      this.dados = dado;
    });
  }

  detalhenews(id) {
    this.route.navigate(["blogdetalhe"], {
      state: { id }
    });
  }
}
