import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "angularfire2";

import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./header/header.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CurriculoComponent } from "./curriculo/curriculo.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogdetalheComponent } from "./blogdetalhe/blogdetalhe.component";

const firebaseConfig = {
  apiKey: "AIzaSyDbMtmDC9tbcNVynd7TN6FKfy9mgdzRU7Y",
  authDomain: "megadansite.firebaseapp.com",
  databaseURL: "https://megadansite.firebaseio.com",
  projectId: "megadansite",
  storageBucket: "megadansite.appspot.com",
  messagingSenderId: "905596783588",
  appId: "1:905596783588:web:99718480c71eaa7c7f15f1",
  measurementId: "G-BJJVC3B7Y1"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    CurriculoComponent,
    BlogComponent,
    BlogdetalheComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
